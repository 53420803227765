
import React from 'react';

export default function footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="card-footer">
            <a href="https://www.margosupplies.com/">Margo Supplies {currentYear}</a>&nbsp;&nbsp;
            <a href = "mailto:info@margosupplies.com">Need Help?</a>
        </div>
    )
}