import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { MdBluetooth } from 'react-icons/md'
import { BsWifi } from 'react-icons/bs';
import FloatingLabel from 'react-bootstrap/FloatingLabel'

const uuid_wifi_config_service = 'b4ad5b8d-d2db-44d6-9d35-5d43b9e5060c';
const uuid_wifi_config_tx = '3c673f3a-382a-4835-8433-c1c1b6b65346';
const uuid_wifi_config_rx = '226285d5-7a5a-448d-8317-dae1fd2d6c36';

//See https://github.com/Dan-Kouba/ble-wifi-setup-manager

export default function SetupWifi() {
    const [showModalAddDevice, setshowModalAddDevice] = useState(false);
    const [pairedDevice, setpairedDevice] = useState('');
    const [pairedDeviceDisplay, setpairedDeviceDisplay] = useState('');
    const [SSID, setSSID] = useState('');
    const [netPassword, setnetPassword] = useState('');

    var encoder = new TextEncoder();

    function configDevice() {
        if (pairedDevice) {
            pairedDevice.gatt.getPrimaryService(uuid_wifi_config_service)
                .then(service => {
                    return service.getCharacteristic(uuid_wifi_config_tx)
                })
                // Send credentials to device
                .then(characteristic => {
                    var credentials_msg = {
                        msg_type: "set_creds",
                        ssid: SSID,
                        password: netPassword,
                    }
                    console.log('Configure WiFi Credentials for network \"' + credentials_msg.ssid + '\"');
                    var message_str = JSON.stringify(credentials_msg);
                    var bytes = encoder.encode(message_str);
                    console.log('TX: ' + message_str);
                    return characteristic.writeValue(bytes);
                })
                .catch(error => {
                    toast.error(error);
                    console.error(error);
                })
        } else {
            toast.error('Not connected to Argon!');
        }
        setshowModalAddDevice(false);
    }

    async function start_Scanner() {
        if (navigator.bluetooth) {
            if (pairedDevice) {
                // Disconnect
                onDisconnected();
            } else {
                // Connect
                onConnectButtonClick();
            }
        } else {
            toast.error("Web Bluetooth API is not available")
            console.log('Error: Web Bluetooth API is not available. Please make sure the "Experimental Web Platform features" flag is enabled.');
        }
    }

    function onDisconnected() {
        if (pairedDevice) {
            toast('Disconnecting...');
            try {
                pairedDevice.gatt.disconnect();

            } catch {

            }
        }
        setpairedDevice(null);
        setpairedDeviceDisplay('');
    }

    function onConnectButtonClick() {
        console.log('Requesting Bluetooth Device...');
        navigator.bluetooth.requestDevice({
            filters: [{
                services: [uuid_wifi_config_service], // Only show devices that have the WiFi config service enabled
            }],
        })
            .then(device => {
                // Connect to the selected device
                console.log('Connecting to ' + device.name + '...');
                toast('Connecting to ' + device.name + '...');
                device.addEventListener('gattserverdisconnected', onDisconnected());
                setpairedDevice(device);
                setpairedDeviceDisplay(device.name);
                console.log(device);
                return device.gatt.connect();
            })
            .catch(error => {
                toast.error(error);
                console.error(error);
                setpairedDevice(null);
            });
    }

    return (
        <div >
            <Modal
                show={showModalAddDevice}
                onHide={() => setshowModalAddDevice(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Setup Wifi Cannon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <button className="btn btn-warning" onClick={start_Scanner}><MdBluetooth />&nbsp;Scan Devices</button>
                    <FloatingLabel controlId="floatingInput" label="Selected Device">
                        <Form.Control type="text" name="SSID" value={pairedDeviceDisplay} readOnly />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Network Name (SSID)">
                        <Form.Control type="text" name="SSID" onChange={e => setSSID(e.target.value)} value={SSID} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Network Password">
                        <Form.Control type="text" name="netPassword" onChange={e => setnetPassword(e.target.value)} value={netPassword} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={configDevice}>
                        Setup Wifi Cannon
                    </Button>
                </Modal.Footer>
            </Modal>
            <button className="btn btn-warning" onClick={() => setshowModalAddDevice(true)}><BsWifi />&nbsp;Wifi Cannon</button>
        </div >
    );
}
