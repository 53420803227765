import React, { useState, useEffect } from 'react';
import RenderAdmin from "./AdminRender"
import SetupWifi from "./Modals/SetupWifi"
import Table from 'react-bootstrap/Table';
import { MdOutlineEmail, MdSmokeFree, MdOutlineIosShare, MdBluetooth } from 'react-icons/md'
import { GiCornerExplosion } from 'react-icons/gi'
import { BsThreeDotsVertical } from 'react-icons/bs'

import './styles.scss';

export default function Dashboard() {
  const [currentUser, setUser] = useState('');

  useEffect(() => {
    //Add Devices once except for refresh button
    setUser(localStorage.getItem('currentUser'));
  });

  return (
    <div>
      <RenderAdmin />
      <div className='card-header'>
        <h1>Hello, {currentUser}</h1>
        Welcome to the Cannon Control for Margo Electra with Cellular control. This web app will help you fire and control your cannons from the web anywhere you need. New devices can be added from the devices tab. 
        <hr />
      </div>
      <div className='card-body'>
        <h2>Tips and Tricks for a successful deployment:</h2>
        <ul>
          <li>Birds find it difficult to adapt to the confusion presented when using several deterrents together. Combine scare cannons with other different audio and visual deterrents for the best effect.</li>
          <li>Deploy cannons before your crops are damaged, not after. It’s easier to keep birds out of the control area than to chase them out once they’ve been rewarded.</li>
          <li>Birds are most active at dawn and dusk.</li>
          <li>In order to reduce your overall sound output and prevent habituation, restrict cannon use when birds are not active.</li>
          <li>A single cannon will protect up to 6 acres of high value crop from persistent birds.</li>
          <li>Relocate the cannon frequently to prevent habituation. It should be moved every 2-3 days when birds are present.</li>
        </ul>
        <h2>Cannon Safety!  <GiCornerExplosion />{' '}<MdSmokeFree /></h2>
        <ul>
          <li>Clear an area of 5m (15ft) surrounding the gas cylinder and cannon of all debris, including flammable and combustible material, such as dried weeds and grass.</li>
          <li>The area directly in front of the megaphone must be clear of all obstructions.</li>
          <li>Never place objects or look directly into the megaphone.</li>
          <li>Never walk in front of or look into the barrel.</li>
          <li>Do not place objects on top of the cannon.</li>
          <li>Do not operate cannon in an enclosed space.</li>
          <li>Keep fire and other sources of heat/ignition away from the cannon.</li>
          <li>Cannon should be on stable horizontal ground when in use.</li>
          <li>Only use LP Gas (propane) or butane gas. Keep canister upright. Do not place in a pit.</li>
          <li>Service cannon after 150kg (330lbs) of use.</li>
          <li>Replace the gas hose every 2 years. Contact Margo Supplies to order replacement.</li>
        </ul>
        <h2>Cannon Troubleshooting:</h2>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Failure</th>
              <th>Cause</th>
              <th>Solution</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={8}>Cannon operates but there is no explosion or sound</td>
              <td>Gas tank valve is not open</td>
              <td>Open the gas valve</td>
            </tr>
            <tr>
              <td>Gas tank is empty or does not have enough pressure</td>
              <td>Fill or replace gas tank</td>
            </tr>
            <tr>
              <td>Hose Fracture Protector Button hasn’t been pushed</td>
              <td>Push the Hose Fracture Protector Button (red) one or more times</td>
            </tr>
            <tr>
              <td>Poor gas mixture</td>
              <td>Gas mixture in the gas tank is not correct, replace gas tank</td>
            </tr>
            <tr>
              <td>Gas valve is blocked or partially blocked</td>
              <td>Open up blockage with a thin wire approx. 0.6mm in diameter</td>
            </tr>
            <tr>
              <td>Ignition gives a weak or no spark at all</td>
              <td>Check the Electrode for effectiveness. The porcelain base could be broken. Replace if necessary. Clean corrosion on any of the connection points between the barrel, electrode and the ground wire. Separate all pieces to clean contact points with an emery cloth. If the electrode is wet, be sure to dry it well.</td>
            </tr>
            <tr>
              <td>The gas valve will not operate</td>
              <td>Check connection points. If properly connected, call the dealer for repair</td>
            </tr>
            <tr>
              <td>Debris in combustion chamber or other opening</td>
              <td>Remove debris and other blockages from around the cannon. Common instances are mouse or spider nests.</td>
            </tr>
            <tr>
              <td rowSpan={2}>Cannon is connected yet fails to operate</td>
              <td>Battery cable is not connected properly</td>
              <td>Ensure the battery terminals are connected. The black battery clip is negative and the red battery clip is positive</td>
            </tr>
            <tr>
              <td>Low battery</td>
              <td>Recharge or connect a new battery</td>
            </tr>
            <tr>
              <td rowSpan={2}>Cannon works, but gives dull explosions</td>
              <td>Wind is too strong; gas/air mixture is disturbed</td>
              <td>Place cannon down wind, out of the wind or behind a screen</td>
            </tr>
            <tr>
              <td>Gas valve is partially blocked</td>
              <td>Open blockage with a thin wire approx. 0.6mm in diameter</td>
            </tr>
            <tr>
              <td rowSpan={2}>Cannon is leaking</td>
              <td>Coupling nut on the gas regulator is not tight enough</td>
              <td>Tighten the coupling nut</td>
            </tr>
            <tr>
              <td>Gas hose is damaged</td>
              <td>Replace the hose</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlignVertical: "center", textAlign: "center", }}><h3>Contact Margo Supplies for replacement parts or for complicated repairs</h3>
                <b><a href="mailto: info@margosupplies.com"><MdOutlineEmail />&nbsp;info@margosupplies.com</a> </b>  | Toll Free: <b>1-888-652-1199</b>
              </td>
            </tr>
          </tbody>
        </Table>
        <h2>How to Add Cannon Control to your Home Screen. </h2>
        <b>For Chrome</b>
        <ul>
          <li>Find the settings menu by clicking the three dots <BsThreeDotsVertical /> in the upper right hand corner on desktop.</li>
          <li>Click “Add to Home screen”</li>
        </ul>
        <b>For Safari</b>
        <ul>
          <li>Tap the share icon <MdOutlineIosShare /> at the bottom of the screen</li>
          <li>Click “Add to Home screen”</li>
        </ul>
        <img className="AddToHomeImages" src={'./images/AddToHomeChrome.jpg'} title="Add To Home Chrome" alt="AddToHomeChrome" />;
        <img className="AddToHomeImages" src={'./images/AddToHomeiOS.png'} title="Add To Home iOS" alt="AddToHomeiOS" />;
        <h2>Future Features:</h2>
        <ul>
          <li>Push Notifications</li>
          <li>Map Grouping</li>
        </ul>
        <h2>Wifi Users Start Here</h2>
        Users with WIFI cannons. Not LTE. Click below to setup a Wifi credentials. <br /> A Bluetooth <MdBluetooth /> enabled device is required.
        <SetupWifi />
      </div>
    </div>
  );
}