import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Footer from "./footer"
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container style={{ padding: "0" }}>
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
