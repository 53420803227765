import Table from 'react-bootstrap/Table';
import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import ParticleService from '../../ParticleService';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import CannonSettings from '../Modals/CannonSettings';
import 'mapbox-gl/dist/mapbox-gl.css';
import DeviceNote from '.././DeviceNote'
import '.././styles.scss';
import {HiOutlineInformationCircle} from 'react-icons/hi';

mapboxgl.accessToken = 'pk.eyJ1IjoiamFja3k0NTY2IiwiYSI6ImNrdm54ODFqcGUzOWsycXE2Y2YxZXR3MDIifQ.pJwfydUY9qaUrkCb2NKxyA';

export default function FullWidthCellRenderer(props) {
  const [device] = useState(props.node.data);
  const [deviceRGB, setdeviceRGB] = useState(false);
  const [userNotes, setuserNotes] = useState('');
  const [userNotesColor, setuserNotesColor] = useState('lightpink');
  const [signal, setSignal] = useState(0.0);
  const [lng, setLng] = useState(0.0);
  const [lat, setLat] = useState(0.0);
  const [accM, setaccM] = useState(0.0);
  const [batteryV, setbatteryV] = useState(0.0);
  const [tempC, settempC] = useState(0.0);
  const [LifeCounter, setLifeCounter] = useState(0.0);
  const [tankCounter, settankCounter] = useState(0.0);
  const [misfireCounter, setmisfireCounter] = useState(0.0);
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    ParticleService.getDeviceNotes(device.id).then((result) => {
      setuserNotes(DeviceNote.getUserNote(result));
      setuserNotesColor('white');
    });

    const fetchingToast = toast.loading("Getting Stats...");
    ParticleService.getCannonStats(device.id).then((result) => {
      if (result.error) {
        toast.update(fetchingToast, { render: result.error, type: toast.TYPE.ERROR, autoClose: "2000", isLoading: false });
        return;
      }
      toast.update(fetchingToast, { render: "SUCCESS", type: toast.TYPE.SUCCESS, autoClose: "1000", isLoading: false });
      const locationSplit = result.result.split(",");
      setbatteryV(locationSplit[0]);
      settempC(locationSplit[1]);
      setLifeCounter(locationSplit[2]);
      settankCounter(locationSplit[3]);
      setmisfireCounter(locationSplit[4]);
    });
  }, []);

  useEffect(() => {
    const fetchingToast = toast.loading("Getting Location...");
    ParticleService.getCannonLocation(device.id).then((result) => {
      if (result.error) {
        toast.update(fetchingToast, { render: result.error, type: toast.TYPE.ERROR, autoClose: "2000", isLoading: false });
        return;
      }
      toast.update(fetchingToast, { render: "SUCCESS", type: toast.TYPE.SUCCESS, autoClose: "1000", isLoading: false });
      const locationSplit = result.result.split(",");
      setLng(locationSplit[1]);
      setLat(locationSplit[0]);
      setaccM(locationSplit[2]);
      const location = new mapboxgl.LngLat(locationSplit[1], locationSplit[0]);
      new mapboxgl.Marker()
        .setLngLat(location)
        .addTo(map.current);
      map.current.flyTo({ center: location });
    });
  }, []);

  useEffect(() => {
    ParticleService.fetchVitals(device.id).then((result) => {
      if (result.error) {
        toast.error(result.error);
        return;
      }
      setSignal(result.diagnostics.payload.device.network.signal.quality);
    });
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [lng, lat],
      zoom: 13,
    });
  });

  useEffect(() => {
    setuserNotesColor('lightpink');

    const delayDebounceFn = setTimeout(() => {
      const newNotes = DeviceNote.setUserNote(device.notes, userNotes);
      ParticleService.setDeviceNotes(device.id, newNotes).then((result) => {
        if (result.error) {
          toast.error(result);
          console.error(result);
        }else{
          console.log("Save Notes: " + userNotes);
          setuserNotesColor('white');
        }
      });
    }, 750)

    return () => clearTimeout(delayDebounceFn)
  }, [userNotes]);

  const rainbow = (event) => {
    const newRGB = event.currentTarget.checked;
    setdeviceRGB(newRGB)
    if (newRGB) {
      toast('Signaling Device', { position: toast.POSITION.BOTTOM_RIGHT })
    }
    ParticleService.signalDevice(device.id, newRGB).then((result) => {
      console.log(result);
    });
  }

  const resetTank = () => {
    toast('Resetting Tank', { position: toast.POSITION.BOTTOM_RIGHT })
    settankCounter(0);
    ParticleService.resetTank(device.id).then((result) => {
      console.log(result);
    });
  }

  return (
    <div className="full-width-panel">
      <Table borderless size="sm" className='datatable'>
        <tbody>
          <tr>
            <th >ID:</th>
            <td>{device.id}</td>
          </tr>
          <tr>
            <th >Battery Voltage:</th>
            <td>{batteryV}V</td>
          </tr>
          <tr>
            <th >Temperature:</th>
            <td>{parseFloat(tempC).toFixed(2)}C ({(parseFloat(tempC) * 9 / 5 + 32).toFixed(2)} F)</td>
          </tr>
          <tr>
            <th >Life Counter:</th>
            <td>{LifeCounter}</td>
          </tr>
          <tr>
            <th >Misfire Counter:</th>
            <td>{misfireCounter} </td>
          </tr>
          <tr>
            <th >Tank Counter: <HiOutlineInformationCircle title="20 lbs bottle last 15,000"/></th>
            <td>{tankCounter} <Button variant="warning" size="sm" onClick={resetTank}>Reset</Button ></td>
          </tr>
          <tr>
            <th >
              <div className='mb-1' >Notes:</div>
              <div className='mb-1'><ToggleButton
                id="toggle-check"
                type="checkbox"
                variant="outline-primary"
                checked={deviceRGB}
                onChange={rainbow}
              >RGB Signal</ToggleButton></div>
              <CannonSettings prop={props} />
            </th>
            <td colSpan={3}>
              <div className='form-group mb-3'>
                <textarea style={{backgroundColor: userNotesColor}} name="notes" type="text" value={userNotes} onChange={e => setuserNotes(e.target.value)} placeholder="Try writing some notes here" className='form-control' />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <div ref={mapContainer} className="map-mini-container">
        <div className="map-mini-sidebar">
          <div>Longitude: {parseFloat(lng).toFixed(4)}</div>
          <div>Latitude: {parseFloat(lat).toFixed(4)}</div>
          <div>Accuracy: {accM}</div>
          <div>Signal: {signal}%</div>
        </div>
      </div>
    </div >
  );
}