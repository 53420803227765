class DeviceNote {

    checkUserNote(note) {
        let defaultObject = { "AdminNotes": "", "UserNotes": "", "UserGroups": "" };
        if (!note) {
            console.log("Return Default");
            defaultObject.UserNotes = note
            return defaultObject;
        }
        try {
            const jsonNote = JSON.parse(note);
            return jsonNote;
        } catch {
            console.log("Fail to Parse");
            console.error(note);
            defaultObject.UserNotes = note
            return defaultObject;
        }
    }

    setUserNote(extNote, newnote) {
        let jsondecode = this.checkUserNote(extNote);
        jsondecode.UserNotes = newnote;
        return JSON.stringify(jsondecode);
    }

    getUserNote(extNote) {
        const jsondecode = this.checkUserNote(extNote);
        if (jsondecode.UserNotes)
            return jsondecode.UserNotes;
        else
            return ''
    }

    addUserGroup(extNote, newgroup) {
        let jsondecode = this.checkUserNote(extNote);
        const currentGroups = jsondecode.UserGroups;
        if (currentGroups) {
            jsondecode.UserGroups = currentGroups + "," + newgroup;
        } else {
            jsondecode.UserGroups = newgroup;
        }
        return JSON.stringify(jsondecode);
    }

    changeUserGroup(extNote, oldgroup, newgroup) {
        let jsondecode = this.checkUserNote(extNote);
        var currentGroups = new Array();
        currentGroups = jsondecode.UserGroups.split(",");
        var newGroups = new Array();
        currentGroups.forEach(obj => {
            if (obj == oldgroup) {
                newGroups.push(newgroup)
            } else {
                newGroups.push(obj)
            }
        })
        jsondecode.UserGroups = newGroups.join(',');
        return JSON.stringify(jsondecode);
    }

    removeUserGroup(extNote, rmgroup) {
        let jsondecode = this.checkUserNote(extNote);
        const currentGroups = jsondecode.UserGroups.split(",");
        let newlist = '';
        currentGroups.forEach((el) => {
            if (el !== rmgroup) {
                if (newlist === '')
                    newlist = el;
                else
                    newlist = newlist + "," + el;
            }
        })
        jsondecode.UserGroups = newlist;
        return JSON.stringify(jsondecode);
    }


    getUserGroups(extNote) {
        const jsondecode = this.checkUserNote(extNote);
        return jsondecode.UserGroups.toString().split(",");
    }

}
export default new DeviceNote();
