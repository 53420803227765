import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import ParticleService from '../../ParticleService';
import SetupWifi from "./SetupWifi"
import { Html5Qrcode } from "html5-qrcode"
import { uniqueNamesGenerator, adjectives, NumberDictionary, animals } from 'unique-names-generator'
import { BsPlusCircleFill, BsRecycle, BsCamera } from 'react-icons/bs';

function randomName() {
    const numberDictionary = NumberDictionary.generate({ min: 10, max: 99 });
    return uniqueNamesGenerator({
        dictionaries: [adjectives, animals, numberDictionary],
        length: 3,
        style: 'capital',
        separator: '',
    });
}

export default function Devices() {
    const [showModalAddDevice, setshowModalAddDevice] = useState(false);
    const [addDeviceID, setaddDeviceID] = useState('');
    const [addDeviceName, setaddDeviceName] = useState(randomName);

    let codescanner = null;

    const addDevice = async () => {
        //Test ID
        if (addDeviceID === '') {
            toast.error("Invalid Device ID");
            return;
        }
        //Test Name
        if (!ParticleService.checkName(addDeviceName)) {
            toast.error("Invalid Device Name");
            return;
        }
        //Claim Device
        const fetchingToast = toast.loading("Adding Device...");
        let response = await ParticleService.claimDevice(addDeviceID);
        if (response.errors) {
            console.error(response);
            toast.update(fetchingToast, { render: response.errors[0], type: toast.TYPE.ERROR, autoClose: "2000", isLoading: false });
            return;
        }
        //Rename Device
        response = await ParticleService.renameDevice(addDeviceID, addDeviceName, ParticleService.getUserToken());
        if (response.errors) {
            toast.update(fetchingToast, { render: response.errors[0], type: toast.TYPE.ERROR, autoClose: "2000", isLoading: false });
            return;
        }
        toast.update(fetchingToast, { render: "Device Added", type: toast.TYPE.SUCCESS, autoClose: "2000", isLoading: false });
        setshowModalAddDevice(false);
        setaddDeviceName(randomName);
        document.dispatchEvent(new Event('dataRefresh'));
    }

    const handleChange = event => {
        if (event.target.name === 'cid') {
            setaddDeviceID(event.target.value);
        } else if (event.target.name === 'cname') {
            setaddDeviceName(event.target.value);
        }
    };

    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        if (decodedResult) {
            if (decodedResult.result.format.format === 6) { //Its a data matrix
                const splitData = decodedText.split(" ");
                console.log("Found " + splitData[0]);

                ParticleService.getDeviceID(splitData[0]).then((result) => {
                    setaddDeviceID(result);
                });
            }
        }
    };

    const qrCodeErrorCallback = (errorMessage, error) => {
        if (error.type) {
            if (error.type != 0) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (showModalAddDevice) {
            codescanner = new Html5Qrcode("qrscanner");
            const config = { fps: 4, qrbox: 100 };
            codescanner.start({ facingMode: "environment" }, config, qrCodeSuccessCallback, qrCodeErrorCallback);
        }
        return () => {
            if (codescanner) {
                if (codescanner.getState() > 0) {
                    codescanner.stop();
                }
            }
        };
    }, [showModalAddDevice])  // Scanner is wrapped in a popup modal

    return (
        <div >
            <Modal
                show={showModalAddDevice}
                onHide={() => setshowModalAddDevice(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Device To Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Cannon ID</Form.Label>
                        <Form.Control type="text" name="cid" onChange={handleChange} value={addDeviceID} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Cannon Name</Form.Label>
                        <Form.Control type="text" name="cname" onChange={handleChange} value={addDeviceName} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Scanner <BsCamera /></Form.Label>
                        <div id="qrscanner"></div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={() => setaddDeviceName(randomName)}>
                        <BsRecycle />&nbsp;Generate Name
                    </Button>
                    <Button variant="primary" onClick={addDevice}>
                        <BsPlusCircleFill />&nbsp;Add
                    </Button>
                </Modal.Footer>
            </Modal>
            <button className="btn btn-success" onClick={() => setshowModalAddDevice(true)}><BsPlusCircleFill />&nbsp;Add Cannon</button>{' '}
        </div >
    );
}
