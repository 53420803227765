import React, { useRef, useEffect, useState } from 'react';
import ParticleService from '../ParticleService';
import { toast } from 'react-toastify';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import './styles.scss';

mapboxgl.accessToken = 'pk.eyJ1IjoiamFja3k0NTY2IiwiYSI6ImNrdm54ODFqcGUzOWsycXE2Y2YxZXR3MDIifQ.pJwfydUY9qaUrkCb2NKxyA';

async function fireAll() {
  toast('Fire All')
  console.log("Fire All");
  const data = await ParticleService.getDevices();
  data.forEach(obj => {
    ParticleService.fireCannon(obj.id).then(callback => {
      if (callback.error) {
        console.error(callback);
        toast.error("Could not fire " + obj.name)
      }
      return callback;
    });
  })
}

class FireAllControl {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';

    const button = this._createButton('map-fire-btn')
    this.container.appendChild(button);
    return this.container;
  }
  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
  _createButton(className) {
    const el = window.document.createElement('button')
    el.className = className;
    el.textContent = 'FIRE ALL';
    el.addEventListener('click', (e) => {
      console.log("Fire All");
      fireAll();
      e.stopPropagation()
    }, false)
    return el;
  }
}

export default function Map() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [currentMarkers] = useState([]);

  function getCenter() {
    //Get preferences from storage
    const storedSettings = JSON.parse(localStorage.getItem("mapbox.lastCentre"));
    if (storedSettings) {
      return mapboxgl.LngLat.convert(storedSettings);
    }
    return new mapboxgl.LngLat(-113.9749, 50.7736);
  }

  function getZoom() {
    //Get preferences from storage
    const storedSettings = JSON.parse(localStorage.getItem("mapbox.lastZoom"));
    if (storedSettings) {
      return storedSettings;
    }
    return 12;
  }

  async function addDevices() {
    let deviceCount = 0;
    let mytoast = toast("Fetching Devices", { autoClose: 30000 })
    if (currentMarkers !== null) {
      for (let i = currentMarkers.length - 1; i >= 0; i--) {
        currentMarkers[i].remove();
      }
    }
    const data = await ParticleService.getDevices();
    deviceCount = data.length;
    data.forEach(obj => {
      ParticleService.getCannonLocation(obj.id).then(locateCallback => {
        deviceCount = deviceCount - 1;
        if (locateCallback.error) {
          toast.error("Could not Find " + obj.name)
        } else {
          const devicelatlng = new mapboxgl.LngLat(locateCallback.result.split(',')[1], locateCallback.result.split(',')[0]);
          const el = document.createElement('div');
          el.className = 'map-marker';
          el.title = obj.name;
          el.style.backgroundImage = `url(${process.env.PUBLIC_URL + '/mapIcons/Location3.svg'})`;
          el.setAttribute('mydata', obj);
          const marker = new mapboxgl.Marker(el)
            .setLngLat(devicelatlng);
          currentMarkers.push(marker);
          marker.addTo(map.current);
          el.addEventListener('click', () => { deviceClick(obj) });
        };
        if (deviceCount <= 0)
          toast.dismiss(mytoast);
      })
    });
  }

  async function deviceClick(device) {
    toast.success("Firing Cannon" + device.name)
    let response = await ParticleService.fireCannon(device.id);
    if (response.errors) {
      console.error(response);
      toast.error(response.errors[0]);
    }
  }

  useEffect(() => {
    //Add Devices once except for refresh button
    toast("Click To Fire", { autoClose: 10000 })
    addDevices();
    document.addEventListener('dataRefresh', () => {
      addDevices();
    });
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: getCenter(),
      zoom: getZoom(),
    });
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    });
    const nav = new mapboxgl.NavigationControl({
      showCompass: true
    });
    //Add basic controls
    map.current.addControl(geolocate);
    map.current.addControl(nav, 'top-right');
    map.current.on('moveend', () => {
      localStorage.setItem("mapbox.lastCentre", JSON.stringify(map.current.getCenter()));
      localStorage.setItem("mapbox.lastZoom", JSON.stringify(map.current.getZoom()));
    });
    //Add Fire all
    map.current.addControl(new FireAllControl(), 'top-right')
  });


  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}
