import { Component } from "react";

const LTEProductID = 10300
const WIFIProductID = 18278

export class ParticleService extends Component {
    static async deleteUser(username) {
        let result = "ok"
        let response = await fetch(`https://api.particle.io/v1/products/${LTEProductID}/customers/${username}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'access_token': this.getUserToken()
            })
        });
        result = await response.json();
        return result;
    }

    static async sendWelcomeEmail(username, password) {
        const sendingUser = localStorage.getItem('currentUser');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password, sendingUser })
        };
        const response = await fetch('/api/Particle/MargoSendWelcome', requestOptions);
        return response;
    }

    static async makeUser(username, password) {
        const responseLTE = await fetch(`https://api.particle.io/v1/products/${LTEProductID}/customers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'email': username,
                'password': password,
                'access_token': this.getUserToken()
            })
        });
        const resultLTE = await responseLTE.json();
        return resultLTE;
    }

    static async getLTEUsers() {
        let rowList = [];
        let page = 1;
        let pages = 1;

        do {
            let requestURL = `https://api.particle.io/v1/products/${LTEProductID}/customers?access_token=${this.getUserToken()}&page=${page}`
            let response = await fetch(requestURL);
            if (response.error) {
                console.error(response.error)
                return rowList;
            }
            const result = await response.json();
            page = page + 1;
            if (result.meta) {
                pages = result.meta.total_pages;
            }
            if (result.customers) {
                // Create rows
                result.customers.forEach(obj => {
                    let row = Object.assign({}, obj);
                    row = Object.assign(row, { product: "LTE" });
                    rowList.push(row);      //Push to dataset
                });
            }
        } while (page <= pages)

        return rowList;
    }

    static async getWIFIUsers() {
        let rowList = [];
        let page = 1;
        let pages = 1;

        do {
            let requestURL = `https://api.particle.io/v1/products/${WIFIProductID}/customers?access_token=${this.getUserToken()}&page=${page}`
            let response = await fetch(requestURL);
            if (response.error) {
                console.error(response.error)
                return rowList;
            }
            const result = await response.json();
            page = page + 1;
            if (result.meta) {
                pages = result.meta.total_pages;
            }
            if (result.customers) {
                // Create rows
                result.customers.forEach(obj => {
                    let row = Object.assign({}, obj);
                    row = Object.assign(row, { product: "LTE" });
                    rowList.push(row);      //Push to dataset
                });
            }
        } while (page <= pages)

        return rowList;
    }

    static async getDeviceID(serialNum) {
        let requestURL = `https://api.particle.io/v1/serial_numbers/${serialNum}?access_token=${this.getUserToken()}`
        let response = await fetch(requestURL);
        if (response.error) {
            console.error(response.error)
        }
        const result = await response.json();
        return result.device_id;
    }

    static async getDevices(id = null) {
        let requestURL = '';
        if (id) {
            requestURL = `https://api.particle.io/v1/devices/${id}?access_token=${this.getUserToken()}`
        } else {
            requestURL = `https://api.particle.io/v1/devices?access_token=${this.getUserToken()}`
        }
        const response = await fetch(requestURL);
        const result = await response.json();
        const devices = [];
        result.forEach(element => {
            if (element.product_id === LTEProductID || element.product_id === WIFIProductID) {
                devices.push(element);
            }
        });
        return (devices);
    }

    static async getDevice(id = null) {
        let requestURL = '';
        if (id) {
            requestURL = `https://api.particle.io/v1/devices/${id}?access_token=${this.getUserToken()}`
        } else {
            requestURL = `https://api.particle.io/v1/devices?access_token=${this.getUserToken()}`
        }
        const response = await fetch(requestURL);
        const result = await response.json();
        return (result);
    }

    static async getProductDevices() {
        let devices = [];
        let page = 1;
        let pages = 1;

        do {
            let requestURL = `https://api.particle.io/v1/products/${LTEProductID}/devices?access_token=${this.getUserToken()}&page=${page}`
            let response = await fetch(requestURL);
            let result = await response.json();
            page = page + 1;
            if (result.meta) {
                pages = result.meta.total_pages;
            }
            result.devices.forEach(element => {
                devices.push(element);
            });
        } while (page <= pages)

        page = 1;
        do {
            let requestURL = `https://api.particle.io/v1/products/${WIFIProductID}/devices?access_token=${this.getUserToken()}&page=${page}`
            let response = await fetch(requestURL);
            let  result = await response.json();
            page = page + 1;
            if (result.meta) {
                pages = result.meta.total_pages;
            }
            result.devices.forEach(element => {
                devices.push(element);
            });
        } while (page <= pages)
        return (devices);
    }

    static async claimDevice(id) {
        const response = await fetch('https://api.particle.io/v1/devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'id': id,
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async addDeviceUser(newUserAccessToken, newUserDeviceIDAdd) {
        const response = await fetch('https://api.particle.io/v1/devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'id': newUserDeviceIDAdd,
                'access_token': newUserAccessToken
            })
        });
        const result = await response.json();
        return result;
    }

    static async renameDevice(id, newName, token) {
        const response = await fetch(`https://api.particle.io/v1/devices/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'name': newName,
                'access_token': token
            })
        });
        const result = await response.json();
        return result;
    }

    static async Login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        };
        const response = await fetch('/api/Particle/particle-login', requestOptions);
        const result = await response.json();
        console.log("login");
        if (result.access_token) {
            result.expires_in = (result.expires_in * 1000) + Date.now(); //Change to absulte expiry
            localStorage.setItem('my-token', JSON.stringify(result));
            localStorage.setItem('currentUser', username);
            return true;
        } else {
            console.error(result);
            return false;
        }
    }

    static logout() {
        console.log("Log out")
        localStorage.removeItem('my-token');
        localStorage.removeItem('currentUser');
        window.location.reload(false);
    }

    static async requestResetPassword(username) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username })
        };
        const response = await fetch('/api/Particle/RequestResetEmail', requestOptions);
        return response;
    }

    static async changePassword(username, password, securityCode) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password, securityCode })
        };
        const response = await fetch('/api/Particle/RequestParticleResetPassword', requestOptions);
        console.log(response);
        return response;
    }

    static getUserToken() {
        const storage = JSON.parse(localStorage.getItem('my-token'));
        if (storage) {
            if (storage.expires_in > Date.now()) {
                return storage.access_token;
            } else {
                console.error("Token Expired:");
                this.logout();
                return false;
            }
        }
    }

    static checkName(nameCheck) {
        const regexName = new RegExp('^[a-zA-Z0-9+-_]+$');
        return regexName.test(nameCheck);
    }

    static async getDeviceNotes(id) {
        const response = await ParticleService.getDevice(id);
        if (response.notes)
            return response.notes;
    }

    static async setDeviceNotes(id, newNotes) {
        const response = await fetch('https://api.particle.io/v1/devices/'.concat(id), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'notes': newNotes,
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async signalDevice(id, newState) {
        const response = await fetch('https://api.particle.io/v1/devices/'.concat(id), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'signal': newState ? (1) : (0),
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async fireCannon(id) {
        const response = await fetch(`https://api.particle.io/v1/devices/${id}/fireCannon`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'arg': "0",
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async resetTank(id) {
        const response = await fetch(`https://api.particle.io/v1/devices/${id}/resetTank`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'arg': "0",
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async setCannonSettings(id, newSettings) {
        const response = await fetch(`https://api.particle.io/v1/devices/${id}/setCannonSettings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'arg': newSettings,
                'access_token': this.getUserToken()
            })
        });
        const result = await response.json();
        return result;
    }

    static async getCannonStats(id) {
        const requestURL = `https://api.particle.io/v1/devices/${id}/stats?access_token=${this.getUserToken()}`
        const response = await fetch(requestURL);
        const result = await response.json();
        return (result);
    }

    static async fetchVitals(id) {
        const requestURL = `https://api.particle.io/v1/diagnostics/${id}/last?access_token=${this.getUserToken()}`
        const response = await fetch(requestURL);
        const result = await response.json();
        return (result);
    }

    static async getCannonSettings(id) {
        const requestURL = `https://api.particle.io/v1/devices/${id}/settings?access_token=${this.getUserToken()}`
        const response = await fetch(requestURL);
        const result = await response.json();
        return (result);
    }

    static async getCannonLocation(id) {
        const requestURL = `https://api.particle.io/v1/devices/${id}/locationCallback?access_token=${this.getUserToken()}`
        const response = await fetch(requestURL);
        const result = await response.json();
        return (result);
    }
}

export default ParticleService
