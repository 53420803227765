import React, { useState, useEffect, useRef } from 'react';
import ParticleService from '../../ParticleService';
import { AgGridReact } from 'ag-grid-react';
import { toast } from 'react-toastify';
import DeviceNote from '.././DeviceNote';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '.././styles.scss';

export default function GroupTable() {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    fetchTableData()
    document.addEventListener('dataRefresh', (event) => {
      fetchTableData();
    });
  }, []);

  async function fetchTableData() {
    const data = await ParticleService.getDevices();
    if (data.length > 0) {
      // Create rows
      let rowList = [];

      data.forEach(obj => {
        const objGroups = DeviceNote.getUserGroups(obj.notes);
        objGroups.forEach(objGroup => {
          if (objGroup) {
            let found = false;
            rowList.forEach(extGroup => {
              if (extGroup.group === objGroup) {
                extGroup.devices.push(obj);
                found = true;
              }
            })
            if (!found) {
              const row = { group: objGroup, devices: [] };
              row.devices.push(obj);
              rowList.push(row);      //Push to dataset
            }
          }
        })
      });
      setRowData(rowList);
    } else {
      toast.error("No Data");
    }
    gridRef.current.api.sizeColumnsToFit()
  }

  function ActionButtons(props) {
    return <div>
      <button className="btn btn-danger" onClick={() => {
        const deviceList = props.data.devices;
        deviceList.forEach(cannon => {
          toast("FIRING! " + cannon.name)
          if (cannon.id) {
            ParticleService.fireCannon(cannon.id).then((result) => {
              if (result.error) {
                toast.error(props.data.name + " " + result.error)
              }
            })
          }
        })
      }}>
        Fire
      </button >{' '}
      <button className="btn btn-warning" onClick={() => {
        const groupToRemove = props.data.group;
        const deviceList = props.data.devices;
        toast("Ungroup " + groupToRemove)
        deviceList.forEach(cannon => {
          if (cannon) {
            ParticleService.setDeviceNotes(cannon.id, DeviceNote.removeUserGroup(cannon.notes, groupToRemove)).then((result) => {
              if (result.error) {
                toast.error(groupToRemove + " " + result.error)
              }
            })
          }
          document.dispatchEvent(new Event('dataRefresh'));
        })
      }}>
        Delete
      </button > {' '}
    </div >
  }

  function deviceListRender(props) {
    const deviceList = props.data.devices;
    const htmlList = Array.from(deviceList).map((data, index) => <li key={index}>{data.name} ({data.id})</li>);
    return (
      <ul className='listNoIndentation'>{htmlList}</ul>
    )
  }

  const [columnDefs] = useState([
    {
      field: 'group', flex: '2', editable: true, valueSetter: params => {
        const currentName = params.data.group;
        const newName = params.newValue;
        const re = /^[a-zA-Z0-9-_ \b]+$/;
        if (!re.test(newName)) {
          toast.error("Bad Name");
          return;
        } else {
          params.data.group = params.newValue;
          gridRef.current.api.forEachNode(function (node) {
            node.data.devices.forEach(obj => {
              console.log(obj.id)
              ParticleService.setDeviceNotes(obj.id, DeviceNote.changeUserGroup(obj.notes, currentName, newName));
            })
          });
        }
        return true;
      }
    },
    { field: 'action', minWidth: 150, cellRenderer: ActionButtons, flex: '1' },
    { field: 'Devices', flex: '2', cellRenderer: deviceListRender }
  ])

  const gridOptionGroup = {
    columnDefs: columnDefs,
    defaultColDef: {
      sortable: true,
      autoHeight: true,
    },
    enableCellTextSelection: true,
    statusBar: {
      statusPanels: [{ statusPanel: 'agAggregationComponent' }],
    },
  };

  return (
    <div className="ag-theme-alpine table-container" >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        gridOptions={gridOptionGroup}>
      </AgGridReact>
    </div >
  );
}
