import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ParticleService from '../ParticleService';
import Footer from ".././footer"
import { useNavigate } from "react-router-dom";
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';

export default function Devices() {
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [password2, setpassword2] = useState('');
    const [securityCode, setsecurityCode] = useState('');

    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        if (password !== password2) {
            toast.error("Password fields must match")
            return;
        }
        if (password.length <= 8) {
            toast.error("Password must be at least 8 characters")
            return;
        }
        ParticleService.changePassword(username, password, securityCode)
            .then(result => {
                if (!result.ok) {
                    toast.error("Error")
                    console.error(result)
                } else {
                    console.log(result)
                    navigate("/");
                }
            })
    }

    return (
        <div className="row justify-content-center">
            <div className='col-lg-5'>
                <div className='card m-5'>
                    <div className='card-header'>
                        <span className="lead">Password Reset</span>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group mb-3'>
                                <p>Username / Email</p>
                                <input type="text" className='form-control' value={username} onChange={e => setusername(e.target.value)} autoComplete="username" required />
                            </div>
                            <div className='form-group mb-3'>
                                <p>New Password</p>
                                <input type="password" className={`form-control ${(password.length <= 8) ? 'input-error' : ''}`} value={password} onChange={e => setpassword(e.target.value)} required />
                            </div>
                            <div className='form-group mb-3'>
                                <p>New Password Again</p>
                                <input type="password" className={`form-control ${(password2.length <= 8) ? 'input-error' : ''}`} value={password2} onChange={e => setpassword2(e.target.value)} required />
                            </div>
                            <div className='form-group mb-3'>
                                <p>Security Code</p>
                                <input type="text" className='form-control' value={securityCode} onChange={e => setsecurityCode(e.target.value)} required />
                            </div>
                            <div className='form-group mb-3'>
                                <button className='btn btn-success d-inline-block' type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
