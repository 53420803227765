import React, { useState, useEffect } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import UserTable from "./tables/UserTable";
import UnassignedDevicesTable from "./tables/UnassignedDevicesTable";
import { uniqueNamesGenerator, adjectives, animals, NumberDictionary } from 'unique-names-generator'
import { BsRecycle } from 'react-icons/bs';
import ParticleService from '../ParticleService';
import { toast } from 'react-toastify';

function randomPSD() {
    const numberDictionary = NumberDictionary.generate({ min: 10, max: 99 });
    return uniqueNamesGenerator({
        dictionaries: [adjectives, animals, numberDictionary],
        length: 3,
        style: 'capital',
        separator: '',
    });
}

export default function Dashboard() {
    const [currentUser, setUser] = useState('');
    const [newUser, setNewUser] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [newUserAccessToken, setNewUserAccessToken] = useState('');
    const [newUserDeviceIDAdd, setNewUserDeviceIDAdd] = useState('');
    const [newUserDeviceName, setnewUserDeviceName] = useState(randomPSD());
    const [newUserPsd, setNewUserPsd] = useState(randomPSD());

    useEffect(() => {
        //Add Devices once except for refresh button
        setUser(localStorage.getItem('currentUser'));
    }, []);

    const addUser = async () => {
        ParticleService.makeUser(newUser, newUserPsd, ParticleService.LTEProductID).then((result) => {
            console.log(result);
            if (result.error)
                toast.error(result);
            if (result.access_token)
                setNewUserAccessToken(result.access_token);
            document.dispatchEvent(new Event('dataRefresh'));
        }
        )
    }

    const addDeviceUser = async () => {
        ParticleService.addDeviceUser(newUserAccessToken, newUserDeviceIDAdd).then((result) => {
            console.log(result);
            if (result.error) {
                toast.error(result);
            }
            //Rename device
            ParticleService.renameDevice(newUserDeviceIDAdd, newUserDeviceName, newUserAccessToken).then((result) => {
                console.log(result);
                if (result.error) {
                    toast.error(result);
                }
            })
        })
        document.dispatchEvent(new Event('dataRefresh'));
        setnewUserDeviceName(randomPSD());
    }

    const sendWelcomeEmail = async () => {
        toast("Sending Welcome Email");
        ParticleService.sendWelcomeEmail(newUser, newUserPsd).then((result) => {
            console.log(result);
            if (result.error) {
                toast.error(result);
            }
        }
        )
    }

    if (currentUser) {
        const domain = currentUser.split('@').pop()
        if (domain === "margosupplies.com") {
            return (
                < div >
                    <div className='card-header'>
                        <h1>Dashboard Controls</h1>
                    </div>
                    <div className='card-body'>
                        <b>Add User</b>
                        <Form >
                            <Row>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Email address">
                                        <Form.Control type="text" name="cid" onChange={e => setNewUser(e.target.value)} value={newUser} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Display Name"  >
                                        <Form.Control type="text" name="cname" onChange={e => setNewUserName(e.target.value)} value={newUserName} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Password" >
                                        <Form.Control type="text" name="password" onChange={e => setNewUserPsd(e.target.value)} value={newUserPsd} />
                                    </FloatingLabel>
                                </Col>
                                <Col >
                                    <Button variant="info" onClick={() => setNewUserPsd(randomPSD)}><BsRecycle /> Generate</Button>{' '}
                                    <Button variant="primary" onClick={addUser}>Add User</Button>
                                </Col>
                            </Row>
                            <b>Add Device to User</b>
                            <Row>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Access Token" >
                                        <Form.Control type="text" value={newUserAccessToken} readOnly />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Device Name" >
                                        <Form.Control type="text" onChange={e => setnewUserDeviceName(e.target.value)} value={newUserDeviceName} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Device ID" >
                                        <Form.Control type="text" onChange={e => setNewUserDeviceIDAdd(e.target.value)} value={newUserDeviceIDAdd} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <Button variant="info" onClick={() => setNewUserDeviceIDAdd('')}>Clear</Button>{' '}
                                    <Button variant="primary" onClick={addDeviceUser}>Add Device</Button>{' '}
                                    <Button variant="success" onClick={sendWelcomeEmail}>Send Welcome Email 😊</Button>{' '}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <UserTable />
                    <br />
                    <div className='card-header'>
                        <h1>Unassigned Devices</h1>
                    </div>
                    <UnassignedDevicesTable />
                    <br />
                </div >
            )
        }
    }
}