import GroupTable from "./tables/GroupTable";
import DeviceTable from "./tables/DeviceTable";
import Footer from ".././footer"

export default function Devices() {
  return (
    <div >
      <div className="card-header">
        <h1>Devices</h1>
      </div>
      <DeviceTable />
      <div className="card-header">
        <h1>Groups</h1>
      </div>
      <GroupTable />
    </div>
  );
}
