import { Route, Routes } from 'react-router-dom';
import ParticleService from './ParticleService';
import Map from "./pages/Map";
import Devices from "./pages/Devices";
import Dashboard from "./pages/Dashboard";
import { Layout } from './Layout';
import './custom.css';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset'

function App() {
  if (!ParticleService.getUserToken()) {
    return (
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/PasswordReset" element={<PasswordReset />} />
        <Route path="*" element={<Login />} />
      </Routes>);
  }
  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/devices" element={<Devices />} />
        <Route exact path="/map" element={<Map />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Layout>
  );
}

export default App;
