import React, { useState, useEffect, useRef } from 'react';
import ParticleService from '../../ParticleService';
import { RiWifiOffFill, RiWifiFill } from 'react-icons/ri';
import { AgGridReact } from 'ag-grid-react';
import { toast } from 'react-toastify';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '.././styles.scss';

export default function UnassignedDevicesTable() {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    fetchTableData()
    document.addEventListener('dataRefresh', () => {
      fetchTableData();
    });
  }, []);

  async function fetchTableData() {
    const data = await ParticleService.getProductDevices();
    if (data.length > 0) {
      // Create rows
      let rowList = [];
      data.forEach(obj => {
        let row = Object.assign({}, obj);
        if (!row.owner) {
          rowList.push(row);      //Push to dataset
        }
      });
      setRowData(rowList);
    } else {
      toast.error("No Data");
    }
    gridRef.current.api.sizeColumnsToFit()
  }

  function IDRender(props) {
    if (props.data.connected === false) {
      return <div><RiWifiOffFill size="20px" color="red" />&nbsp;{props.value}</div>
    } else {
      return <div><RiWifiFill size="20px" color="green" /> & nbsp; { props.value }</div >
    }
  }

  const [columnDefs] = useState([
    { field: 'id', cellRenderer: IDRender, },
    { field: 'name', },
    { field: 'last_heard', }
  ])

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      width: 100,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      autoHeight: true,
    },
    enableCellTextSelection: true,
    statusBar: {
      statusPanels: [{ statusPanel: 'agAggregationComponent' }],
    },
  };

  return (
    <div>
      <div style={{ height: 400 }} className="ag-theme-alpine" >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          gridOptions={gridOptions}
        >
        </AgGridReact>
      </div>
    </div>
  );
}
