import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import ParticleService from '../../ParticleService';
import { AgGridReact } from 'ag-grid-react';
import { RiWifiOffFill, RiWifiFill } from 'react-icons/ri';
import { BsFillPencilFill, BsWifi } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import DeviceNote from '.././DeviceNote'
import FullWidthCellRenderer from "./FullWidthCellRenderer";
import Moment from 'react-moment';
import AddDevice from '../Modals/AddDevice';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '.././styles.scss';
import { CgChevronDoubleLeft } from 'react-icons/cg';

async function fireAll() {
  toast('Fire All')
  console.log("Fire All");
  const data = await ParticleService.getDevices();
  data.forEach(obj => {
    ParticleService.fireCannon(obj.id).then(callback => {
      if (callback.error) {
        console.error(callback);
        toast.error("Could not fire " + obj.name)
      }
      return callback;
    });
  })
}

export default function DeviceTable() {
  const [rowData, setRowData] = useState([]);
  const narrowView = window.innerWidth < 500 ? true : false;
  const [newGroupName, setnewGroupName] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    fetchTableData()
    document.addEventListener('dataRefresh', (event) => {
      fetchTableData();
    });
  }, []);

  async function fetchTableData() {
    const data = await ParticleService.getDevices();
    if (data.length > 0) {
      // Create rows
      let rowList = [];
      data.forEach(obj => {
        let row = Object.assign({}, obj);
        row = Object.assign(row, { isFullWidthDetail: false }); // Add detail flag for full width printing
        rowList.push(row);      //Push to dataset
      });
      setRowData(rowList);
    } else {
      toast.error("No Data");
    }
    gridRef.current.api.sizeColumnsToFit()
  }

  const rowClick = useCallback((params) => {
    //Rework data
    const clickedRow = params.data;
    if (clickedRow.isFullWidthDetail || params.column.colId === 'name')
      return;
    console.log(params.columnApi)
    let currentFullWidth = '';
    let newArray = [];
    //Find current full width
    gridRef.current.api.forEachNode(function (node) {
      if (node.data.isFullWidthDetail) {
        currentFullWidth = node.data.id;
      }
    });
    //Re-add existing data
    gridRef.current.api.forEachNode(function (node) {
      const nodeID = node.data.id;
      //Add none detail rows
      if (!node.data.isFullWidthDetail) {
        newArray.push(node.data);
      }

      //Insert new detail row from clicked row
      if ((nodeID === clickedRow.id) && (!node.data.isFullWidthDetail) && (clickedRow.id !== currentFullWidth)) {
        let newRow = Object.assign({}, node.data);
        newRow.isFullWidthDetail = true;
        newArray.push(newRow);
      }
    });
    setRowData(newArray);
    gridRef.current.api.sizeColumnsToFit();
  }, []);


  const isFullWidthRow = useCallback((params) => {
    return params.rowNode.data.isFullWidthDetail;
  }, []);

  const fullWidthCellRenderer = useMemo(() => {
    return FullWidthCellRenderer;
  }, []);

  const getRowHeight = useCallback((params) => {
    // return larger height for full width rows
    if (params.data.isFullWidthDetail) {
      if (narrowView)
        return 700;
      else
        return 310;
    } else {
      return 46;
    }
  }, []);

  function onlineIcon(props) {
    const dateToFormat = props.data.last_heard;
    if (props.value === false) {
      return <div><RiWifiOffFill size="20px" color="red" />&nbsp;{narrowView ? null : <Moment date={dateToFormat} format="DD-MMM-YY" />}</div>
    } else {
      return <div><RiWifiFill size="20px" color="green" /></div>
    }
  }

  function setGroupName(e) {
    const re = /^[a-zA-Z0-9-_ \b]+$/;
    if (e.target.value === '' || re.test(e.target.value))
      setnewGroupName(e.target.value)
  }

  function groupDevices() {
    if (!newGroupName) {
      toast.error("Enter a Group name")
      return;
    }
    toast('Grouping Devices');
    const rowstoGroup = gridRef.current.api.getSelectedRows();
    rowstoGroup.forEach(node => {
      if (!node.isFullWidthDetail) {
        ParticleService.setDeviceNotes(node.id, DeviceNote.addUserGroup(node.notes, newGroupName));
      }
    })
    document.dispatchEvent(new Event('dataRefresh'));
    setnewGroupName('');
  }

  function ActionButtons(props) {
    return <div><button className="btn btn-danger" ref={(ref) => {
      if (!ref) return;
      //attach onClick event handler to the real DOM element
      ref.onclick = (e) => {
        e.stopPropagation();
        toast("FIRING! " + props.data.name)
        ParticleService.fireCannon(props.data.id).then((result) => {
          if (result.error)
            toast.error(props.data.name + " " + result.error)
        });
      };
    }}>
      Fire
    </button >{' '}
      <button className="btn btn-secondary">
        Details
      </button >
    </div >
  }

  function nameFieldRender(props) {
    return <div >
      {props.value}{' '}
      <BsFillPencilFill />
    </div >
  }

  const [columnDefs] = useState([
    {
      field: 'name', flex: '2', headerCheckboxSelection: true, checkboxSelection: true, cellRenderer: nameFieldRender, editable: true, valueSetter: params => {
        if (ParticleService.checkName(params.newValue)) {
          ParticleService.renameDevice(params.data.id, params.newValue, ParticleService.getUserToken());
          params.data.name = params.newValue;
        } else {
          toast.error("Invalid Device Name");
        }
        return true;
      }
    },
    { field: 'id', flex: '2', hide: narrowView },
    { field: 'action', minWidth: 150, flex: '1', cellRenderer: ActionButtons, },
    { field: 'online', flex: '1', cellRenderer: onlineIcon, }
  ])

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      sortable: true,
      resizable: true,
    },
    suppressHorizontalScroll: true,
    rowSelection: 'multiple',
    onCellClicked: rowClick,
    suppressRowClickSelection: true,
    isFullWidthRow: isFullWidthRow,
    fullWidthCellRenderer: fullWidthCellRenderer,
    getRowHeight: getRowHeight,
    enableCellTextSelection: true,
    statusBar: {
      statusPanels: [{ statusPanel: 'agAggregationComponent' }],
    },
  };

  return (
    <div>
      <div className="card-header">
        <div className='deviceButtonHeader'>
          <div className='textWindow'>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 500 }}
              overlay={<Tooltip id="button-tooltip" >
                Name may only contain letters, numbers, spaces, dashes, and underscores
              </Tooltip>}>
              <FloatingLabel controlId="floatingInput" label="Group Name">
                <Form.Control type="text" onChange={setGroupName} value={newGroupName} />
              </FloatingLabel>
            </OverlayTrigger>
          </div>
          <div className='flexitem'>
            <Button variant="primary" onClick={groupDevices}>Group Devices</Button>
          </div>
          <div className='flexitem'>
            <AddDevice />
          </div>
          <div className='flexitem firebutton'>
            <Button variant="danger" onClick={fireAll}>Fire ALL</Button>
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine table-container" >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          gridOptions={gridOptions}>
        </AgGridReact>
      </div >
    </div >
  );
}
