import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ParticleService from '../ParticleService';
import Footer from ".././footer"
import { useNavigate } from "react-router-dom";
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    const [username, setusername] = useState(localStorage.getItem('lastUser'));
    const [password, setpassword] = useState('');

    const navigate = useNavigate();

    function handlePasswordReset(event) {
        if (!username) {
            toast.error("Please Enter Email")
            return;
        }
        ParticleService.requestResetPassword(username)
            .then(result => {
                if (result.status === 400)
                    toast.error("No such user found")
                else
                    toast("Check your email")
                console.log(result);
            })
        navigate("/PasswordReset");
    }

    function handleSubmit(event) {
        event.preventDefault();
        localStorage.setItem('lastUser', username);
        ParticleService.Login(username, password)
            .then(result => {
                if (!result) {
                    toast.error("Invalid Login")
                    console.error("result " + result)
                } else {
                    //Hacky way to move navigation
                    window.location.reload(false);
                }
            })
    }

    return (
        <div className="row justify-content-center">
            <div className='col-lg-5'>
                <div className='card m-5'>
                    <div className='card-header'>
                        <span className="lead">Cannon Control Login</span>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group mb-3'>
                                <p>Email</p>
                                <input name="username" type="text" className='form-control' value={username} onChange={e => setusername(e.target.value)} autoComplete="username" required/>
                            </div>
                            <div className='form-group mb-3'>
                                <p>Password</p>
                                <input name="password" type="password" className='form-control' value={password} onChange={e => setpassword(e.target.value)} autoComplete="current-password" required/>
                            </div>
                            <div>
                                <button className='btn btn-success d-inline-block' type="submit">Submit</button>
                                <a onClick={handlePasswordReset} className="btn btn-link" id="forgot-password-button">Forgot password?</a>
                            </div>
                            <div>
                                <a href = "mailto:info@margosupplies.com?subject=Cannon Control Demo">Looking for a Demo?</a>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
