import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import ParticleService from './ParticleService';
import { FaSync, FaStore } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import './NavMenu.css';

function refresh() {
  console.log("fetch new data");
  document.dispatchEvent(new Event('dataRefresh'));
}

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-custom navbar-expand-sm navbar-toggleable-sm ng-black box-shadow" container dark>
          <NavbarBrand tag={Link} to="/devices"><img className="navbar-logo" src="/android-chrome-512x512.png" alt="logo" />Margo Cannon Control</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem  >
                <NavLink onClick={() => { refresh(); this.toggleNavbar(); }} className="text-light" ><FaSync size={20} /></NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={this.toggleNavbar} className="text-light" to="/dashboard">Dashboard</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={this.toggleNavbar} className="text-light" to="/map">Map</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} onClick={this.toggleNavbar} className="text-light" to="/devices">Devices</NavLink>
              </NavItem>
              <NavItem onClick={ParticleService.logout} >
                <NavLink tag={Link} className="text-light" to="/">Log Out</NavLink>
              </NavItem>
              <NavItem >
                <a className="text-light nav-link" href="https://margosupplies.com/us-en/"><FaStore size={20} /></a>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      </header>
    );
  }
}
