import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import NumberPicker from "react-widgets/NumberPicker";
import { toast } from 'react-toastify';
import ParticleService from '../../ParticleService';
import { CgTimer } from 'react-icons/cg';

import "react-widgets/styles.css";

export default function CannonSettings(props) {
    const [showModalAddDevice, setshowModalAddDevice] = useState(false);
    const [changesDisabled, setchangesDisabled] = useState(true);
    const [scheduleChoice, setSchedule] = useState(0);
    const [scheduleShots, setShots] = useState(0);
    const [QT1EN, setQT1EN] = useState(false);
    const [QT1ONHOUR, setQT1ONHOUR] = useState(10);
    const [QT1ONMIN, setQT1ONMIN] = useState(10);
    const [QT1OFFHOUR, setQT1OFFHOUR] = useState(10);
    const [QT1OFFMIN, setQT1OFFMIN] = useState(10);
    const [QT2EN, setQT2EN] = useState(false);
    const [QT2ONHOUR, setQT2ONHOUR] = useState(10);
    const [QT2ONMIN, setQT2ONMIN] = useState(10);
    const [QT2OFFHOUR, setQT2OFFHOUR] = useState(10);
    const [QT2OFFMIN, setQT2OFFMIN] = useState(10);
    const [QT3EN, setQT3EN] = useState(false);
    const [QT3ONHOUR, setQT3ONHOUR] = useState(10);
    const [QT3ONMIN, setQT3ONMIN] = useState(10);
    const [QT3OFFHOUR, setQT3OFFHOUR] = useState(10);
    const [QT3OFFMIN, setQT3OFFMIN] = useState(10);


    useEffect(() => {
        if (showModalAddDevice) {
            const fetchingToast = toast.loading("Getting Settings...");
            ParticleService.getCannonSettings(props.prop.data.id).then((result) => {
                if (result.error) {
                    toast.update(fetchingToast, { render: result.error, type: toast.TYPE.ERROR, autoClose: "2000", isLoading: false });
                    return;
                }
                toast.update(fetchingToast, { render: "SUCCESS", type: toast.TYPE.SUCCESS, autoClose: "1000", isLoading: false });
                const settingsSplit = result.result.split(",");
                setSchedule(Number(settingsSplit[0]));
                setShots(Number(settingsSplit[1]));
                setQT1EN(settingsSplit[2] === '1' ? true : false);
                setQT2EN(settingsSplit[3] === '1' ? true : false);
                setQT3EN(settingsSplit[4] === '1' ? true : false);

                setQT1ONHOUR(Number(settingsSplit[5]));
                setQT1OFFHOUR(Number(settingsSplit[6]));
                setQT1ONMIN(Number(settingsSplit[7]));
                setQT1OFFMIN(Number(settingsSplit[8]));

                setQT2ONHOUR(Number(settingsSplit[9]));
                setQT2OFFHOUR(Number(settingsSplit[10]));
                setQT2ONMIN(Number(settingsSplit[11]));
                setQT2OFFMIN(Number(settingsSplit[12]));

                setQT3ONHOUR(Number(settingsSplit[13]));
                setQT3OFFHOUR(Number(settingsSplit[14]));
                setQT3ONMIN(Number(settingsSplit[15]));
                setQT3OFFMIN(Number(settingsSplit[16]));

                setchangesDisabled(false);
            });
        }
    }, [showModalAddDevice]);

    const handleChange = (event) => {
        if (event.target.id === 'trgsch') {
            setSchedule(event.target.value);
        } else if (event.target.id === 'trgnum') {
            setShots(event.target.value);
        }
    }

    const sendSettings = async () => {
        const sendString = (QT1EN ? '1' : '0') + "," + QT1ONHOUR + "," + QT1ONMIN + "," + QT1OFFHOUR + "," + QT1OFFMIN + ","
            + (QT2EN ? '1' : '0') + "," + QT2ONHOUR + "," + QT2ONMIN + "," + QT2OFFHOUR + "," + QT2OFFMIN + ","
            + (QT3EN ? '1' : '0') + "," + QT3ONHOUR + "," + QT3ONMIN + "," + QT3OFFHOUR + "," + QT3OFFMIN + ","
            + scheduleShots + ","
            + scheduleChoice;
        ParticleService.setCannonSettings(props.prop.data.id, sendString).then((result) => {
            if (result.error) {
                toast.error("failed to set device");
            }
        })
        setshowModalAddDevice(false);
    }

    return (
        <div >
            <Modal
                show={showModalAddDevice}
                onHide={() => setshowModalAddDevice(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Cannon Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table  >
                        <tbody>
                            <tr>
                                <td>Trigger Schedule</td>
                                <td><Form.Select aria-label="Floating label select example" id="trgsch" value={scheduleChoice} onChange={handleChange} disabled={changesDisabled}>
                                    <option value="0">On Demand Only</option>
                                    <option value="1">1 Min</option>
                                    <option value="2">5 Min</option>
                                    <option value="3">9 Min</option>
                                    <option value="4">15 Min</option>
                                    <option value="5">30 Min</option>
                                    <option value="6">60 Min</option>
                                    <option value="7">120 Min</option>
                                    <option value="8">240 Min</option>
                                    <option value="9">Random Max 5</option>
                                    <option value="10">Random Max 15</option>
                                    <option value="11">Random Max 60</option>
                                </Form.Select></td>
                            </tr>
                            <tr>
                                <td>Shots Per Trigger</td>
                                <td><Form.Select aria-label="Floating label select example" id="trgnum" value={scheduleShots} onChange={handleChange} disabled={changesDisabled}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </Form.Select></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><div style={{ textAlignVertical: "center", textAlign: "center" }}>Hour : Minute </div></td>
                            </tr>
                            <tr>
                                <td rowSpan={2}>Quiet Time 1<Form.Check type="switch" label="Enable" id="QT1ENtoggler" checked={QT1EN} onChange={e => setQT1EN(e.target.checked)} /></td>
                                <td style={{ display: "flex" }}>
                                    ON&nbsp;&nbsp;<NumberPicker min={0} max={23} value={QT1ONHOUR} step={1} message="123" onChange={value => setQT1ONHOUR(value)} disabled={!QT1EN} />
                                    <NumberPicker min={0} max={59} value={QT1ONMIN} step={1} onChange={value => setQT1ONMIN(value)} disabled={!QT1EN} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ display: "flex" }}>
                                    OFF&nbsp;<NumberPicker min={0} max={23} value={QT1OFFHOUR} step={1} onChange={value => setQT1OFFHOUR(value)} disabled={!QT1EN} />
                                    <NumberPicker min={0} max={59} value={QT1OFFMIN} step={1} onChange={value => setQT1OFFMIN(value)} disabled={!QT1EN} />
                                </td>
                            </tr>

                            <tr>
                                <td rowSpan={2}>Quiet Time 2<Form.Check type="switch" label="Enable" id="QT2ENtoggler" checked={QT2EN} onChange={e => setQT2EN(e.target.checked)} /></td>
                                <td style={{ display: "flex" }}>
                                    ON&nbsp;&nbsp;<NumberPicker min={0} max={23} value={QT2ONHOUR} step={1} message="123" onChange={value => setQT2ONHOUR(value)} disabled={!QT2EN} />
                                    <NumberPicker min={0} max={59} value={QT2ONMIN} step={1} onChange={value => setQT2ONMIN(value)} disabled={!QT2EN} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ display: "flex" }}>
                                    OFF&nbsp;<NumberPicker min={0} max={23} value={QT2OFFHOUR} step={1} onChange={value => setQT2OFFHOUR(value)} disabled={!QT2EN} />
                                    <NumberPicker min={0} max={59} value={QT2OFFMIN} step={1} onChange={value => setQT2OFFMIN(value)} disabled={!QT2EN} />
                                </td>
                            </tr>

                            <tr>
                                <td rowSpan={2}>Quiet Time 3<Form.Check type="switch" label="Enable" id="QT3ENtoggler" checked={QT3EN} onChange={e => setQT3EN(e.target.checked)} /></td>
                                <td style={{ display: "flex" }}>
                                    ON&nbsp;&nbsp;<NumberPicker min={0} max={23} value={QT3ONHOUR} step={1} message="123" onChange={value => setQT3ONHOUR(value)} disabled={!QT3EN} />
                                    <NumberPicker min={0} max={59} value={QT3ONMIN} step={1} onChange={value => setQT3ONMIN(value)} disabled={!QT3EN} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ display: "flex" }}>
                                    OFF&nbsp;<NumberPicker min={0} max={23} value={QT3OFFHOUR} step={1} onChange={value => setQT3OFFHOUR(value)} disabled={!QT3EN} />
                                    <NumberPicker min={0} max={59} value={QT3OFFMIN} step={1} onChange={value => setQT3OFFMIN(value)} disabled={!QT3EN} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <ul>
                        <li>Regular timer will not fire during quiet time</li>
                        <li>24 Hour Time 00:00 through 23:59</li>
                        <li>Quiet Times are overriden with on demand firing</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" size="lg" onClick={sendSettings} disabled={changesDisabled}>
                        Save Settings
                    </Button>
                </Modal.Footer>
            </Modal>
            <button className="btn btn-success" onClick={() => setshowModalAddDevice(true)}><CgTimer />&nbsp;Change Timer</button>
        </div >
    );
}
