import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ParticleService from '../../ParticleService';
import { AgGridReact } from 'ag-grid-react';
import { toast } from 'react-toastify';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '.././styles.scss';

export default function UserTable() {
  const [modalShow, setModalShow] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [deleteData, setdeleteData] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    fetchTableData()
    document.addEventListener('dataRefresh', () => {
      fetchTableData();
    });
  }, []);

  async function fetchTableData() {
    const LTEUsers = await ParticleService.getLTEUsers();
    const WIFIUsers = await ParticleService.getWIFIUsers();
    let rowList = [];
    if (LTEUsers) {
      // Create rows
      LTEUsers.forEach(obj => {
        let row = Object.assign({}, obj);
        row = Object.assign(row, { product: "LTE" });
        rowList.push(row);      //Push to dataset
      });
    } 
    if (WIFIUsers.customers) {
      // Create rows
      WIFIUsers.customers.forEach(obj => {
        let row = Object.assign({}, obj);
        row = Object.assign(row, { product: "WIFI" });
        rowList.push(row);      //Push to dataset
      });
    } 
    setRowData(rowList);
  }

  function ActionButtons(props) {
    return (
      <div>
        <button className="btn btn-danger" onClick={() => {
          setModalShow(true);
          setdeleteData(props.data.username);
        }}>DELETE</button >{' '}
      </div >)
  }

  function deviceList(props) {
    const htmlList = Array.from(props.data.devices).map((data, index) => <li key={index}>{data}</li>);
    return (
      <ul className='listNoIndentation'>{htmlList}</ul>
    )
  }

  const [columnDefs] = useState([
    { headerName: 'User Name', field: 'username' },
    { headerName: 'Product', field: 'product' },
    { headerName: 'Devices', field: 'devices', cellRenderer: deviceList },
    { field: 'Action', cellRenderer: ActionButtons }
  ])

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      width: 100,
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      autoHeight: true,
    },
    enableCellTextSelection: true,
    statusBar: {
      statusPanels: [{ statusPanel: 'agAggregationComponent' }],
    },
  };

  return (
    <div>
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Delete {deleteData} ?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => {
            setModalShow(false);
            ParticleService.deleteUser(deleteData).then((result) => {
              if (result.error) {
                console.error(result.error);
                toast.error(deleteData + " " + result.error)
              }
              fetchTableData();
            });
          }}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ height: 800 }} className="ag-theme-alpine" >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          gridOptions={gridOptions}
        >
        </AgGridReact>
      </div>
    </div>
  );
}
